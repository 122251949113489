import React from 'react';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { ProductsGrid } from '@components/ProductsGrid';
import { ProductTile } from '@components/Tiles/ProductTile/ProductTile';
import { ProductBasicData } from '@api';
import { ProductTile as IProductTile } from '@commons/product';
import styles from './OptionalAdditionsModal.module.scss';

export interface OptionalAdditionsProps {
  title: string;
  products: ProductBasicData[];
  isOpen: boolean;
  toggleClose: () => void;
}

export const OptionalAdditionsModal = ({
  title,
  products,
  isOpen,
  toggleClose,
}: OptionalAdditionsProps) => {
  const handleClick = () => {
    if (isOpen) {
      toggleClose();
    }
  };

  return (
    <ModalWindow
      title={title}
      className={styles.modal}
      open={isOpen}
      onClose={toggleClose}
      closeButtonSize={{
        height: 11.78,
        width: 11.78,
      }}
    >
      <>
        <ProductsGrid
          products={products as unknown as IProductTile[]}
          xs={6}
          sm={4}
          lg={4}
          customTileComponent={(product) => <ProductTile product={product} onClick={handleClick} />}
        />
      </>
    </ModalWindow>
  );
};
